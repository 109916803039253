<template>
  <tr class="offers-list__row" :class="{'offers-list__row--disabled': isLoading }" @mouseenter="shownRatioInput = item.id" @mouseleave="shownRatioInput = false">
    <td v-text="index + 1" />
    <td class="text-left text-caption">
      <Article class="offers-list__row--sku" :item="item" />
    </td>
    <td class="text-left text-caption" v-html="highlighted" @mouseenter="(event) => showPopover(event, item)" @mouseleave="hidePopover" />
    <td class="text-left text-caption" v-html="categoryName" />
    <td class="text-left text-caption" v-html="vendorName" />
    <td class="text-left text-caption" v-text="warehouseName" />
    <td class="text-left text-caption" v-text="item.warehouse_total_stock" />
    <td class="text-left text-caption" v-html="prices" />
    <td class="text-left text-caption">
      <GmidLink :gmid="item.gmid" v-if="item.gmid" />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import hitHighlighter from '@/helpers/hitHighlighter';
import Article from '@/components/common/Product/Article';
import popover from '@/helpers/popover';
import { formatPrice } from '@/helpers/formatters';
import GmidLink from '@/components/common/GmidLink';

export default {
  components: {
    Article,
    GmidLink,
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    search: { type: String, required: false, default: undefined },
  },
  data() {
    return {
      isLoading: false,
      popoverShow: false,
      shownRatioInput: false,
    };
  },
  computed: {
    ...mapGetters('reference', ['getWarehouseRegionName']),
    highlighted() {
      return hitHighlighter(this.item.name || '', this.search || '');
    },
    categoryName() {
      return this.item.category?.name ?? '—';
    },
    vendorName() {
      return this.item.vendor?.name ?? '—';
    },
    warehouseName() {
      if (!this.item.warehouse_items || !this.item.warehouse_items.length) return '–';
      return this.getWarehouseRegionName(this.item.warehouse_items[0].warehouse_id);
    },
    prices() {
      return formatPrice(this.item.warehouse_items, 'price_per_unit_factor');
    },
  },
  methods: {
    showPopover(event, item) {
      popover.show(event, item, 'image_url');
    },
    hidePopover() {
      popover.hide();
    },
  },

};
</script>

<style lang="scss" scoped>
.offers-list {
  &__row {
    transition: all 0.1 ease-in-out;
    background-color: hsla(0, 0%, 98%, 1);

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &--sku {
      text-decoration: underline;
      cursor: pointer;
      color: hsl(0, 0%, 0%);

      &:hover {
        color: var(--v-primary-base);
      }
    }

    &--bind {
      border-radius: 6px;
      background-color: rgba(28, 78, 255, 0.08);
      font-weight: 400;
    }

    &--unbind {
      border-radius: 6px;
      background-color: rgba(0,0,0,0.03);
      font-weight: 400;
    }

    &--link {
      text-decoration: underline;
      text-decoration-color: inherit;
      text-decoration-style: solid;
    }
  }
}

</style>

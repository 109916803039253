<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-toolbar dense elevation="0">
        <v-toolbar-title v-html="highlight(`${title}`, title)" />
      </v-toolbar>
    </v-col>
    <v-col cols="12">
      <v-simple-table class="offers-list">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="2%">№</th>
              <th class="text-left" width="5%">Артикул</th>
              <th class="text-left" width="auto">Название товара</th>
              <th class="text-left" width="7%">Категория</th>
              <th class="text-left" width="7%">Бренд</th>
              <th class="text-left" width="7%">Склад</th>
              <th class="text-left" width="2%">Остаток</th>
              <th class="text-left" width="12%">Цена</th>
              <th class="text-left" width="5%" v-html="'Артикул&nbsp;ГМ'" />
            </tr>
          </thead>
          <tbody>
            <ItemRow :item="item" :index="index" v-for="(item, index) in items" :key="item.id" :search="search" />
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import hitHighlighter from '@/helpers/hitHighlighter';
import ItemRow from '@/components/Search/ItemRow';

export default {
  components: {
    ItemRow,
  },
  props: {
    title: { type: String, required: true },
    items: { type: Array, required: true },
    search: { type: String, required: true },
  },
  methods: {
    highlight(input, sample) {
      return hitHighlighter(input || '', sample || '');
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <div v-text="title" />
        </v-toolbar-title>
        <v-spacer />
        <div class="d-flex justify-end align-center">
          <QueryFilterObserver v-bind="filters.search" />
        </div>
      </v-toolbar>
    </v-col>

    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>

    <template v-else>
      <template v-if="items.length">
        <v-col cols="12" v-for="item in items" :key="item[0]">
          <ItemsGroup :title="item[0]" :items="item[1]" @update="FETCH_GROUPED_ITEMS(queryParams)" v-if="item[1].length" :search="search" />
        </v-col>
      </template>
      <v-col class="d-flex justify-center" cols="12" v-else>Нет подходящих предложений</v-col>
    </template>
  </v-container>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters, mapActions } from 'vuex';

import QueryFilterObserver from '@/components/QueryFilterObserver';

import ItemsGroup from '@/components/Search/ItemsGroup';

export default {
  components: {
    ItemsGroup,
    QueryFilterObserver,
  },
  data() {
    return {
      title: 'Поиск товаров по названию',
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters('inventory_items', ['groupedItemsByOwners']),
    items() {
      if (!this.groupedItemsByOwners) return [];
      if ('ГдеМатериал' in this.groupedItemsByOwners) delete this.groupedItemsByOwners['ГдеМатериал'];

      return Object.entries(this.groupedItemsByOwners);
    },
    queryParams() {
      return this.$route.query;
    },
    search() {
      return this.$route.query.search || '';
    },
    filters() {
      return {
        search: {
          component: 'v-text-field',
          name: 'search',
          attrs: {
            dense: true,
            disabled: false,
            'hide-details': false,
            'append-icon': 'mdi-magnify',
            class: ['search-field', 'ml-4'],
          },
        },
      };
    },
  },
  watch: {
    queryParams: {
      immediate: true,
      deep: true,
      handler: debounce(async function (params) {
        this.isLoading = true;

        try {
          await this.FETCH_GROUPED_ITEMS(params);

          this.isLoading = false;
        } catch (e) {
          this.isLoading = e.isAxiosCancelError;

          if (!e.isAxiosCancelError) this.$notify.showErrorMessage(e.response);
          if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
        }
      }, 300),
    },
  },
  methods: {
    ...mapActions('inventory_items', ['FETCH_GROUPED_ITEMS']),
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  background-color: hsl(0, 0%, 100%);
  border-bottom: 1px solid hsla(0, 0%, 92%, 1);
  z-index: 5;
}

.search-field {
  width: 400px;
  font-size: 12px;
}

::v-deep .search-field .v-label {
  font-size: 22px;
}
</style>
